import { bb } from "$json";

var arbitrary = bb.propFinder(bb.conf, "arbitrary");
/**
 * Get the part of pathname with which we can match our model,
 * case-insensitively
 *
 * @param {String} pathname
 *
 * @return {String} (when found)
 * @return {undefined} (when not found)
 */
function getIdentifyingPartOfPath(pathname) {
  var chunks = pathname.split("/"),
    path,
    i = 0;
  while (
    ((path = chunks.pop()), typeof path !== undefined && /(\.|^$)/.test(path))
  );
  return (path && path.toLowerCase()) || arbitrary("proxymap.default");
}

/**
 * Export the Usabilla function
 */

export function getSetting(key, fallback) {
  return arbitrary(
    ["proxymap", getIdentifyingPartOfPath(window.location.pathname), key]
      .filter(Boolean)
      .join("."),
    fallback
  );
}

export function getProxyString() {
  return getIdentifyingPartOfPath(window.location.pathname);
}
