/*
 * high-on-info-tb.js
 *
 * As a simpler replacement to other
 * renditions of high-on-info.
 *
 */

import { bb } from "$json";
import Dialogue from "$Dialogue";

((doc, $) => {
  function createClickable(textNode, descriptor, dialogue) {
    /*
      Take this text node and make three elements;
      one for the text before,
      one for the clickable
      one for thr rest of the text.
    */
    try {
      let text = textNode.textContent;
      let clickTextLength = descriptor.length;
      let clickTextPos = text.indexOf(descriptor);

      let preText = text.slice(0, clickTextPos);
      let clickText = doc.createTextNode(descriptor);
      let postText = text.slice(clickTextPos + clickTextLength, text.length);

      let container = doc.createElement("span");

      let pre = doc.createTextNode(preText);

      let link = doc.createElement("a");
      link.href = "";
      link.appendChild(clickText);
      link.className = "p-high-on-info-tb-link";
      link.addEventListener("click", function (e) {
        e.preventDefault();
        e.stopPropagation();
        let inner = doc.createElement("div");

        let title = doc.createElement("h2");
        let titleTxt = doc.createTextNode(descriptor);
        title.append(titleTxt);
        let content = doc.createElement("div");
        let contentTxt = doc.createTextNode(dialogue.content);
        content.append(contentTxt);
        // In case markdown is active, use it.
        try {
          $(content).showdown();
        } catch (e) {
          window.console && window.console.warn(e);
        }
        inner.append(title, content);
        dialogue.render(inner);
      });

      let post = doc.createTextNode(postText);

      container.appendChild(pre);
      container.appendChild(link);
      container.appendChild(post);

      return container;
    } catch (err) {
      return doc.createElement("span");
    }
  }

  $(doc).on("bb:finalHandleData", (e, data) => {
    if (data && data.informationsources) {
      // Create a map of info source descriptors.
      let descriptors = new Map();
      data.informationsources.forEach(info => {
        if (!info.isurl) {
          let dialogue = new Dialogue();
          dialogue.content = info.content;

          descriptors.set(info.description, {
            belongsto: info.belongsto,
            dialogue: dialogue
          });
        }
      });
      // For each descriptor, walk the element who owns the info source and replace the node.
      let cb = (source, descriptor, map) => {
        if (descriptor.length) {
          let tw = doc.createTreeWalker(
            doc.querySelector(`.group [data-id="${source.belongsto}"]`),
            NodeFilter.SHOW_TEXT,
            null,
            false
          );

          var node = tw.nextNode(); // Move to first child.
          while (node) {
            // Must call nextNode before altering the currentNode. Otherwise, the tw returns no nextNode.
            let handlenode = tw.currentNode;
            node = tw.nextNode();
            if (handlenode.textContent.includes(descriptor)) {
              let replacement = createClickable(
                handlenode,
                descriptor,
                source.dialogue
              );
              handlenode.replaceWith(replacement);
            }
          }
        }
      };
      descriptors.forEach(cb);
    }
  });
})(document, jQuery);
