// final-node.js

/*
  A plugin to target the final node and
  make a report and feedback buttons, similar
  to nta:three-in-one.
*/

((doc, $) => {
  var reportURL;
  $(doc).on("bb:preHandleData", (e, data) => {
    if (data && data.groups && data.groups.length > 0) {
      if (data.groups[0].name === "main.document") {
        // Grab the doc url, and remove it from the controls.
        data.groups[0].controls.forEach((control, i) => {
          if (control.controltype === "linklabel") {
            reportURL = control.url;
            data.groups[0].controls.splice(i, 1);
          }
        });
      }
    }
  });
  $(doc).on("bb:postHandleData", (e, data) => {
    if (data && data.groups && data.groups.length > 0) {
      if (data.groups[0].name === "main.document") {
        // Create the container for the functions.
        var $container = $('<div class="bb-p-three-in-one-container"></div>');

        // Make a download report button
        var $download = $('<div class="container download-container"></div>');
        $download.append(`
            <a
              data-datatype="Text"
              type="application/pdf"
              href="${reportURL}"
              class="bb-external report pdf bb-text bbm-standard-text"
              target="_blank"
              rel="noopener"
              download="${reportURL.substr(0, reportURL.indexOf("."))}.pdf"
              data-type="linklabel">
                Samenvatting als PDF downloaden
            </a>`);
        $container.append($download);

        // Make the feedback buttons.
        var $feedback = $(
          '<div id="bb-p-three-in-one-feedback" class="container feedback-container"></div>'
        );
        $feedback
          .append("<label>Was dit nuttig?</label>")
          .append(
            '<button class="feedback-button" data-value="Positief"><span class="a-offscreen">Ja</span></button>'
          )
          .append(
            '<button class="feedback-button" data-value="Negatief"><span class="a-offscreen">Nee</span></button>'
          );

        // Handler
        var group;
        if (data && data.userinfo) $feedback.data("userinfo", data.userinfo);
        group = data.groups.filter(function (g) {
          return g.current;
        })[0];
        $feedback.data("nodename", group.name);
        if (data && data.modelname) $feedback.data("modelname", data.modelname);
        if (data && data.modelversion)
          $feedback.data("modelversion", data.modelversion);
        $(doc).off(".three-in-one-events", ".feedback-button");
        $(doc).one(
          "click.three-in-one-events",
          ".feedback-button",
          function (e) {
            var vote =
              $(e.target).data("value") !== undefined
                ? $(e.target).data("value")
                : $(e.target).parents("button").data("value");
            // Send the vote to the feedback model and update the inner text
            $.getJSON("open?", {
              "modelname": "Feedback",
              "username": "feedback",
              "password": "feedback",
              "fmt": "json",
              "fdbk:f": vote,
              "fdbk:n": $feedback.data("nodename"),
              "fdbk:u": $feedback.data("userinfo")
                ? $feedback.data("userinfo")["fullname"]
                : "",
              "fdbk:m": $feedback.data("modelname"),
              "fdbk:v": bb.getVar("version") && bb.getVar("version").join("."),
              "fdbk:a":
                [
                  bb.getVar("replyserveraddress"),
                  bb.getVar("replyserverport")
                ].join(":") +
                "/" +
                bb.getVar("proxyredirect"),
              "fdbk:s": bb.getVar("sessionid"),
              "fdbk:d": location.href.replace(/(password=)(?:[^&]+)/, "$1***"),
              "fdbk:b": navigator.userAgent
            });

            // Add the vote to the hiddden interface.
            $("input.bbm-feedback-vote").attr("value", vote);
            $feedback.html("<label>Bedankt voor uw feedback</label>");
          }
        );

        $container.append($feedback);

        // Reveal

        $("#bb-q").prepend($container);
        $("html,body").animate({ scrollTop: 0 });
        setTimeout(function () {
          $container.toggleClass("showing");
        }, 800);
      }
    }
  });
})(document, jQuery);
