/* global jQuery */
// custom-checkboxes.js

/*
 * A simple plugin to replace
 * checkboxes with a custom design.
 * Cross-browser and working with all
 * forms of output; grids, checklists
 * and mutli-checklists
 *
 * Author: Tim Bauwens
 * Copyright Berkeley Bridge 2019
 *
 */

((doc, $) => {
  const addCustomCheckbox = checkbox => {
    checkbox.className += " a-offscreen p-custom-checkboxes";
    checkbox.insertAdjacentHTML(
      "afterEnd",
      `
        <label
          class="custom-checkbox"
          for="${checkbox.id}"
        ></label>
       `
    );
  };

  const addCheckBoxes = () => {
    // If there are any checkboxes, deal with them
    const checkboxes = $("#bb-q").find(
      'input[type="checkbox"]:not(.p-custom-checkboxes)'
    );
    checkboxes.each((_, checkbox) => {
      addCustomCheckbox(checkbox);
    });
  };

  $(doc).on("bb:postHandleData", (_, data) => {
    if (!data || !data.groups) return;
    addCheckBoxes();
  });

  $(doc).on("bb:updated", (_, $widget, { controltype }) => {
    if (controltype === "grid" || controltype === "checkmultilist") {
      const checkboxes = $widget.find(
        'input[type="checkbox"]:not(.p-custom-checkboxes)'
      );
      checkboxes.each((_, checkbox) => {
        addCustomCheckbox(checkbox);
      });
    }
  });
})(document, jQuery);
